<template>
	<div class="popup">
		<div class="popup-inner">
			<div class="popup-text">
				<slot />
			</div>
			<button class="popup-close" @click="TogglePopup()">
				Back
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "PopUp",
	props: ['TogglePopup']
}
</script>

<style scoped>
.popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background-color: rgba(0, 0, 0, 0.2);
	
	display: flex;
	align-items: center;
	justify-content: center;

	.popup-inner {
		background: #FFF;
		padding: 32px;
		align-content: center;
		text-align: center;
	}

	.popup-text {
		max-height: 60vh;
		margin-bottom: 5vh;
		overflow: scroll;
		text-align: left;
	}
}
</style>